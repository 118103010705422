import React, { useState } from 'react';
import map from '../assets/gwave-map.png'

function Card({ imgSrc, title, subtitle, flagSrc, delay }) {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className={`col-lg-2 col-md-3 col-sm-4 wow bounceInUp`} data-wow-delay={delay}>
            <div className="card bg-white position-relative" style={{ width: '100%', height: 250 }}>
                <img src={imgSrc} className="card-img-top" alt={`Card Image ${title}`} style={{ objectFit: 'cover', height: 150 }} />
                <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <p
                        className="card-subtitle mb-2 text-muted"
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                    >
                        {subtitle}
                    </p>
                    <div className={`custom-tooltip ${showTooltip ? 'show' : ''}`}>{subtitle}</div>
                </div>
                <img src={flagSrc} className="rounded-circle position-absolute" style={{ width: 20, top: 10, right: 10 }} alt="Small Icon" />
            </div>
        </div>
    );
}

function Availability() {
    const cardsData = [
        { imgSrc: 'img/es-land.jpg', title: 'Spain', subtitle: 'From the heart of Europe, delivering the finest of Spanish goods with a touch of Mediterranean flair.', flagSrc: 'img/spainFlag.jpg', delay: '0.2s' },
        { imgSrc: 'img/moroccoLand.png', title: 'Morocco', subtitle: 'Where North African treasures meet global markets- Bridging trade between the Atlantic and the Sahara.', flagSrc: 'img/moroccoFlag.png', delay: '0.3s' },
        { imgSrc: 'img/egyptLand.jpeg', title: 'Egypt', subtitle: 'Reviving the ancient trade routes of Egypt – Delivering the best from the Nile to the world.', flagSrc: 'img/egyptFlag.png', delay: '0.5s' },
        { imgSrc: 'img/uzbekLand.webp', title: 'Uzbekistan', subtitle: 'Opening doors to Central Asia- Uzbekistan’s silk road legacy to modern trade routes.', flagSrc: 'img/uzbekFlag.jpg', delay: '0.7s' },
        { imgSrc: 'img/turkeyLand.webp', title: 'Türkiye', subtitle: 'Connecting East and West through Türkiye’s premium exports- Where tradition meets innovation.', flagSrc: 'img/turkeyFlag.jpg', delay: '0.9s' },
        { imgSrc: 'img/nigeriaLand.webp', title: 'Nigeria', subtitle: 'Unlocking Africa’s potential- Bringing Nigeria’s rich resources into the global market.', flagSrc: 'img/nigeriaFlag.jpg', delay: '1.1s' },
    ];

    return (
        <>
            <div className="container-fluid bg-light py-6" id="available">
                <div className="container">
                    <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
                        <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Our Presence </small>
                        <h1 className="display-10 mb-5">India to..</h1>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-12 wow bounceInUp" data-wow-delay="0.1s">
                            {/* <world-map className="map" /> */}
                            <img src={map} alt="Logo" style={{ width: '100%', height: 'auto' }} />
                            </div>
                        {cardsData.map((card, index) => (
                            <Card key={index} {...card} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Availability;